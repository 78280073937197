body {
  font-family: 'Roboto', Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  background-color: #f0f4f8;
  color: #333;
}

header {
  background-color: #2c3e50;
  color: #ecf0f1;
  text-align: center;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

h1 {
  margin: 0;
  font-size: 2.5em;
}

main {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1rem;
}

.nested-list {
  list-style-type: none;
  padding-left: 0;
  width: 100%;
}

.nested-item {
  margin-bottom: 1rem;
}

.tab-list {
  list-style: none;
}

.tab-item, .category-item, .sound-item {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  transition: box-shadow 0.3s ease;
  min-width: min-content;
  max-width: 900px;
}

.tab-item:hover, .category-item:hover, .sound-item:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.category-item.dragging {
  opacity: 0.7;
  background: #f0f0f0;
  border: 2px dashed #666;
}

.category-item.over {
  border: 2px dashed #666;
}

.tab-header, .category-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
}

.tab-name, .category-name {
  font-weight: bold;
  font-size: 1.2em;
  color: #2c3e50;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  margin: 4px 2px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
  text-decoration: none;
}

.btn:hover {
  background-color: #2980b9;
}

.btn i {
  margin-right: 5px;
}

.add-category-btn, .add-sound-btn, .delete-category-btn, .delete-btn,
.toggle-sounds-btn, #save-button, #logout-btn, .edit-tab-btn, .delete-tab-btn,
.add-tab-btn, .main-category-btn, .not-main-category-btn, .delete-sound-btn,
.premium-btn, .not-premium-btn, .save-button, .edit-button {
  border: outset;
  border-color: #c9c9c9;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.delete-category-btn, .delete-btn, .delete-tab-btn {
  background-color: #e74c3cad;
}

.delete-sound-btn {
  margin-right: 10px;
  background-color: #e74c3cad;
}

.delete-category-btn:hover, .delete-btn:hover, .delete-tab-btn:hover, .delete-sound-btn:hover {
  background-color: #e74c3c;
}

.add-category-btn, .add-sound-btn, .add-tab-btn, .save-button {
  background-color: #2ecc7199;
}

.add-category-btn:hover, .add-sound-btn:hover, .add-tab-btn:hover, .save-button:hover {
  background-color: #2ecc71;
}

.edit-tab-btn, .edit-button {
  background-color: #f39c12ad;
}

.edit-tab-btn:hover, .edit-button:hover {
  background-color: #f39c12;
}

.toggle-sounds-btn {
  background-color: #9b59b6bf;
}

.toggle-sounds-btn:hover {
  background-color: #9b59b6;
}

.main-category-btn {
  background-color: #ffcb2dc7;
  cursor: default;
}
.not-main-category-btn {
  background-color: #E3DFDEC6!important;
}

.main-category-btn:hover {
  background-color: #ffcb2df5;
}
.not-main-category-btn:hover {
  background-color: #ffcb2df5;
}

.premium-btn {
  background-color: #2D5EFF9E;
}
.premium-btn:hover {
  background-color: #2D5EFFF5;
}
.not-premium-btn {
  background-color: #E3DFDEC6;
}
.not-premium-btn:hover {
  background-color: #2D5EFF9E;
}

.show-player-btn {
  border: none;
  background: none;
  cursor: pointer;
}
.show-player-btn i {
  transform: scale(1.5);
}

.add-tab-btn {
  margin-left: 40px;
}

.category-info {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.category-description {
  width: 97%;
  height: 60px;
  margin: 0.5rem 0px;
  resize: vertical;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 0.5rem;
}

.sound-list {
}

.save-button-container {
  text-align: center;
  margin-top: 20px;
}

.sound-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 10px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 0.9em;
  border: none;
  box-shadow: none;
  flex-wrap: wrap;
  cursor: move;
}

.sound-item.dragging {
  opacity: 0.7;
  background: #f0f0f0;
  border: 2px dashed #666;
}

.sound-item.over {
  border: 2px dashed #666;
}

.sound-item:last-child {
  border-bottom: none;
}

.sound-info {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.sound-name {
  min-width: 300px;
}

.sound-info-line {
  white-space: nowrap;
  padding-right: 10px;
}

.sound-duration {
  color: #666;
}

.sound-controls {
  display: flex;
  align-items: center;
}

.play-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-right: 10px;
}

.play-btn:focus {
  outline: none;
}

.play-icon {
  width: 20px;
  height: 20px;
  fill: #3498db;
}

.sound-item-container {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.sound-thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.tab-thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.cat-thumbnail {
  width: 25px;
  height: 25px;
  object-fit: cover;
  margin-right: 10px;
}

.audio-player {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 5px;
  margin-top: 2px;
  width: 100%;
}

.audio-player .play-pause {
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
}

.audio-player .progress-container {
  flex-grow: 1;
  background-color: #e0e0e0;
  border-radius: 5px;
  height: 10px;
  cursor: pointer;
  margin: 0 10px;
  overflow: hidden;
}

.audio-player .progress-bar {
  background-color: #3498db;
  border-radius: 5px;
  height: 100%;
  width: 0;
  transition: width 0.1s linear;
}

.audio-player .time {
  font-size: 12px;
  color: #666;
  display: flex;
  align-items: center;
}

.audio-player .time span {
  margin: 0 5px;
}

.sound-thumbnail-container {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
.tab-thumbnail-container {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
.tab-thumbnail-container-name {
  display: flex;
  align-items: center;
  margin-left: 40px;
}
.edit-sound-img-btn {
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translate(-50%, -50%);
  display
  : none;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
}
.sound-thumbnail-container:hover .edit-sound-img-btn {
  display: block;
}
.cat-thumbnail-name-container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.cat-thumbnail-container {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 5px;
  background: #666666;
}
.edit-cat-img-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display
  : none;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
}
.cat-thumbnail-container:hover .edit-cat-img-btn {
  display: block;
}

.edit-tab-img-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display
  : none;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
}
.tab-thumbnail-container:hover .edit-tab-img-btn {
  display: block;
}

.export-sound-img-btn {
  position: absolute;
  top: 50%;
  left: 80%;
  transform: translate(-50%, -50%);
  display
  : none;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
}
.sound-thumbnail-container:hover .export-sound-img-btn {
  display: block;
}

.import-sound-img-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display
  : none;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
}


.ready-to-import:hover .export-sound-img-btn,
.ready-to-import:hover .edit-sound-img-btn {
  display: none!important;
}

.show-import.import-sound-img-btn{
  display: block;
}

.popup-block {
  position: fixed;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: white; /* Optional: background color */
  box-shadow: 0 3px 10px rgb(0 0 0 / 70%); /* Optional: shadow for better visibility */
  z-index: 1000; /* Make sure it appears above all other content */
}
/* Style for the image */
.popup-block img {
  width: 100px;
  height: 100px;
  object-fit: cover; /* Ensures the image covers the defined size without distortion */
}
/* Style for the close icon */
.popup-block .close-icon {
  font-size: 30px;
  color: red;
  cursor: pointer;
}

.drag-handle {
  cursor: grab;
  padding: 5px;
  color: #666;
  font-size: 20px;
  display: flex;
  align-items: center;
  /*position: absolute;*/
  left: 30px;
}

.drag-handle:active {
  cursor: grabbing;
}

.tab-item {
  cursor: default;
}

.tab-item.over {
  border: 2px dashed #666;
}

.tab-item.dragging {
  opacity: 0.7;
  background: #f0f0f0;
  border: 2px dashed #666;
}

.mode-toggle {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.mode-label {
  margin-left: 10px;
  font-size: 14px;
  color: #666;
}

.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;

  border: outset;
  border-color: #c9c9c9;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 18px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  transition: .4s;
  border: outset;
  border-color: #c9c9c9;
}

input:checked + .slider {
  background-color: #2ecc7199;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.category-info.compact-mode > *:not(.cat-thumbnail-name-container) {
  display: none;
}

.drag-handle-cat {
  left: 53px;
}

.language-button {
  cursor: pointer;
  top: -12px;
  margin-left: 5px;
  position: relative;
}

.localization {
  flex-wrap: wrap;
  justify-content: space-between;
  min-width: 500px;
}

.popup-block-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
